<script>
import Layout from '../../../layout/Layout.vue'
import DefaultMixin from '../../mixin'
import ConsoleMenuItem from "components/console/components/MenuItem.vue"
import {UInput} from 'uloc-vue'

export default {
  name: 'ConsolePage',
  mixins: [DefaultMixin],
  components: {
    Layout,
    ConsoleMenuItem,
    UInput
  },
  data () {
    return {
      busca: null,
      menu: [
        {name: 'suporte', label: 'Suporte', icon: 'fa-duotone fa-headset', route: 'console.suporte'},
        {name: 'tarefas', label: 'Tarefas', icon: 'fa-duotone fa-list-check', route: 'console.tarefas'},
        {name: 'clientes', label: 'Clientes', icon: 'fa-duotone fa-users', route: 'console.clientes'},
        {name: 'produtos', label: 'Produtos e Serviços', icon: 'fa-duotone fa-bell-concierge', route: 'console.produtos'},
        {name: 'financeiro', label: 'Financeiro', icon: 'fa-duotone fa-money-check', route: 'console.financeiro'},
        {name: 'leiloes', label: 'Leilões', icon: 'fa-duotone fa-gavel', route: 'console.leiloes', disabled: true},
        {name: 'servers', label: 'Infra', icon: 'fa-duotone fa-desktop', route: 'console.infra', disabled: true},
        {name: 'backup', label: 'Backup', icon: 'fa-duotone fa-database', route: 'console.infra', disabled: true},
        {name: 'relatorios', label: 'Relatórios', icon: 'fa-duotone fa-chart-user', route: 'console.infra', disabled: true},
      ],
    }
  },
  computed: {
  },
  mounted () {
    window.setTimeout(() => {
      this.erplayout.erpheader.setMenuModuleToggle(true)
    }, 400)
    document.body.classList.add('console-module-body')
  },
  beforeDestroy () {
    this.erplayout.erpheader.setMenuModuleToggle(false)
    document.body.classList.remove('console-module-body')
  },
  destroyed () {
  },
  methods: {
  },
  meta: {
    title: 'Console',
    name: 'Console'
  }
}
</script>

<template>
  <layout class="console-layout" no-menu no-title hfull>
    <div class="ev2-menu w-menub">
      <div class="content">
        <div style="margin-right: 70px">
          <img src="@/assets/images/logo-black.png" style="width: 60px" />
        </div>
        <div class="tab">
          <div class="title">Operador ID</div>
          <div class="val">{{$uloc.auth.session.user.id}}</div>
        </div>
        <div class="tab">
          <div class="title">Operador</div>
          <div class="val">{{$uloc.auth.session.user.name}}</div>
        </div>
        <div class="tab" v-if="$uloc.auth.session && $uloc.auth.session.user.papeis && $uloc.auth.session.user.papeis.worker && $uloc.auth.session.user.papeis.worker.office">
          <div class="title">
            Papel
            <i class="fal fa-circle-question">
              <u-tooltip :offset="[5,5]">Cargo atual</u-tooltip>
            </i>
          </div>
          <div class="val">{{$uloc.auth.session.user.papeis.worker.office.name}}</div>
        </div>
        <!--<div class="tab">
          <div class="title">Tarefas</div>
          <div class="val">0</div>
        </div>
        <div class="tab">
          <div class="title">Tarefas Atrasadas</div>
          <div class="val">0</div>
        </div>
        <div class="tab">
          <div class="title">Bônus</div>
          <div class="val">R$ 0,00 <small class="font-10">(em breve)</small></div>
        </div>-->
        <div class="tab full text-right">
          <div style="width: 300px; display: inline-flex" class="hide">
            <u-input v-model="busca" hide-underline inverted-light class="sl-grey-input full-width" placeholder="Pesquisar" :before="[{icon: 'search', handler () {}}]" />
          </div>
        </div>
      </div>
      <div class="menu-bottom content-menu">
        <console-menu-item
            v-for="m in menu"
            :key="m.name"
            :name="m.name"
            :icon="m.icon"
            :label="m.label"
            :route="m.route"
            :disabled="m.disabled"
        />
      </div>
    </div>
    <router-view />
  </layout>
</template>

<style lang="stylus" src="../../assets/default.styl"></style>
